import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Observable, OperatorFunction, catchError, map, pipe, tap, throwError } from 'rxjs';
import { _convartParams, _cleanObjectOfEmpty } from '../utils/http.utils';
import { MessageService } from 'primeng/api';
import { FailedApiHttpErrorResponse2, PostApiResponse2 } from '../interfaces/common-http.interfaces';


@Injectable({ providedIn: 'root' })
export class NewHttpService {
  private readonly baseUrl: string = environment.publicUrl;
  private readonly mainUrl: string = environment.mainUrl;
  private readonly newApiUrl: string = environment.newPublicUrl;

  constructor(
    private http: HttpClient,
    private _messageService: MessageService
  ) { }

  getData<Res = any>(
    endpoint: string,
    params: any = null,
    options: NewGetApiMethodsCallOptions = defaultNewGetOptions
  ): Observable<Res> {
    const route = this.newApiUrl + endpoint;
    return this.http.get<Res>(
      route,
      {
        params: _cleanObjectOfEmpty(params),
      }
    ).pipe(
      tap((response: any) => {
        if (!response) {
          throw Error('Response is empty!');
        }
      }),
      catchError((err) => {
        if (options?.allowMsg || options?.allowErrMsg) {
          // console.error('err get '+ route + ' : ', err);
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: options?.msg || err?.error?.data || err?.error?.message || err?.message|| 'Error',
          });
        }
        throw Error(options?.msg || err?.error?.data || err?.error?.message || err?.message|| 'Error');
      })
    );
  }

  postData<Res extends {[key: string]: any} = {[key: string]: any}>(
    endpoint: string,
    data: {params?: any; body?: any; [key: string]:any} = {},
    config: NewPostApiMethodsCallOptions = defaultNewPostOptions
  ): Observable<PostApiResponse2<Res>> {
    const route = this.newApiUrl + endpoint;
    const method: 'put' | 'post' = config?.method || 'post';
    const { body, params, ...rest } = data!;

    const basicArgs: RequiredPutArrguments = [
      route,
      body! || rest,
    ];

    const mapFunction = (res: PostApiResponse2<Res>) => {
      // empty response case
      if (!res) {
        throwError(() => 'Response is empty!');
      }
      // succesed case false
      if (!res?.succeeded) {
        throwError(() => res?.message || 'Failed to post data!');
      }
      return res;
    };

    const tapFunction = (res: PostApiResponse2<Res>) => {
      // Success Msg Case
      if(config?.allowSuccessMsg || config?.allowMsg ) {
        this._messageService.add({
          severity: 'success',
          summary: res?.message || 'Success',
          detail: res.message || res?.data?.['detail'] || res?.data?.['message'] || config?.successMsg || 'successfully done!',
        });
      }
    };
    const catchErrorFunction = (err: CustomHttpErrorResposne) => {
      console.error('catchError, '+ route + ' : ', err);
      if (config?.allowErrMsg || config?.allowMsg) {
        console.log("post data error:", err)
        if(typeof err?.error === 'string') {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error,
          });
          throwError(() => err.error);
        }

        // const errorsListKeys = isObject(err.error.errors)? Object.keys(err.error.errors).join(','):'';
        // this._messageService.add({
        //   severity: 'error',
        //   summary: err?.error?.title,
        //   detail: errorsListKeys ? err?.error?.detail?.concat(' : '+errorsListKeys): err?.error?.detail,
        // });
        if(err.error?.errors && Object.keys(err.error?.errors).length) {
          Object.keys(err.error?.errors).forEach((key) => {
            this._messageService.add({
              severity: 'error',
              summary: err.error.title || 'Error',
              detail: key + ': ' +err.error?.errors[key].join(', '),
            });
          });
        } else {
          this._messageService.add({
            severity: 'error',
            summary: err.error.title || 'Error',
            detail: err.error.detail || err.error.message || 'Error',
          });
        }
      }
      return throwError(() => err.error);
    }
    const operators: [
      PostApiResOperatorFunction<Res>,
      PostApiResOperatorFunction<Res>,
      PostApiResOperatorFunction<Res>
    ] = [
      map(mapFunction),
      tap(tapFunction),
      catchError(catchErrorFunction)
    ];

    if(method === 'put') {
      return this.http.put<PostApiResponse2<Res>>(
        ...basicArgs
      ).pipe(
        ...(operators)
      ) as Observable<PostApiResponse2<Res>>;
    } else {

      return this.http.post<PostApiResponse2<Res>>(
        ...basicArgs,
        {
          params: _cleanObjectOfEmpty(params!),
        }
      ).pipe(
        ...(operators)
      ) as Observable<PostApiResponse2<Res>>;
    }
  }


  deleteData<Res extends any = any>(
    endpoint: string,
    data: {params?: any; body?: any}|null = null,
    config: NewPostApiMethodsCallOptions = defaultNewPostOptions
  ): Observable<PostApiResponse2<Res>> {
    const route = this.newApiUrl + endpoint;
    return this.http.post<PostApiResponse2<Res>>(
      route,
      data?.body,
      {
        params: _cleanObjectOfEmpty(data?.params),
      }
    ).pipe(
      tap((res: PostApiResponse2) => {
        // empty response case
        if (!res) {
          throwError(() => new Error('Response is empty!'));
        }
        // Success Msg Case
        if(config?.allowSuccessMsg || config?.allowMsg ) {
          this._messageService.add({
            severity: 'success',
            summary: res?.data?.title || 'Success',
            detail: res?.data?.detail || res?.data?.message || config?.successMsg || 'successfully done!',
          });
        }
      }),
      catchError((err: any|FailedApiHttpErrorResponse2|string) => {
        console.error('catchError, '+ route + ' : ', err);
        if (config?.allowErrMsg || config?.allowMsg) {
          if(typeof err === 'string') {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: err,
            });
            return throwError(() => new Error(err));
          }
          if(err.error?.errors && Object.keys(err.error?.errors).length) {
            Object.keys(err.error?.errors).forEach((key) => {
              this._messageService.add({
                severity: 'error',
                summary: err.error.title || 'Error',
                detail: key + ': ' +err.error?.errors[key].join(', '),
              });
            });
          } else {
            this._messageService.add({
              severity: 'error',
              summary: err.error.title || 'Error',
              detail: err.error.detail || err.error.message || 'Error',
            });
          }
        }
        return throwError(() => err);
      })
    ) as Observable<PostApiResponse2<Res>>;
  }


  
  delete<Res extends any = any>(
    endpoint: string,
    data: {params?: any; body?: any}|null = null,
    config: NewDeleteApiMethodsCallOptions = defaultNewDeleteOptions
  ): Observable<PostApiResponse2<Res>> {
    const route = this.newApiUrl + endpoint;
    return this.http.delete<PostApiResponse2<Res>>(
      route,
      data=
      {
        body:data?.body,
        params: _cleanObjectOfEmpty(data?.params),
      }
    ).pipe(
      tap((res: PostApiResponse2) => {
        // empty response case
        if (!res) {
          throwError(() => new Error('Response is empty!'));
        }
        // Success Msg Case
        if(config?.allowSuccessMsg || config?.allowMsg ) {
          this._messageService.add({
            severity: 'success',
            summary: res?.data?.title || 'Success',
            detail: res?.data?.detail || res?.data?.message || config?.successMsg || 'successfully done!',
          });
        }
      }),
      catchError((err: any|FailedApiHttpErrorResponse2|string) => {
        console.error('catchError, '+ route + ' : ', err);
        if (config?.allowErrMsg || config?.allowMsg) {
          if(typeof err === 'string') {
            this._messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: err,
            });
            return throwError(() => new Error(err));
          }
          if(err.error?.errors && Object.keys(err.error?.errors).length) {
            Object.keys(err.error?.errors).forEach((key) => {
              this._messageService.add({
                severity: 'error',
                summary: err.error.title || 'Error',
                detail: key + ': ' +err.error?.errors[key].join(', '),
              });
            });
          } else {
            this._messageService.add({
              severity: 'error',
              summary: err.error.title || 'Error',
              detail: err.error.detail || err.error.message || 'Error',
            });
          }
        }
        return throwError(() => err);
      })
    ) as Observable<PostApiResponse2<Res>>;
  }

  // deleteData<Res extends PostApiResponse = PostApiResponse>(route: string, options: any, config: CustomPostApiMethodsCallOptions = {
  //   method: 'delete',
  //   errMsg: 'failed in process of remove!',
  //   allowErrMsg: true,
  //   successMsg: 'successfully removed!',
  //   allowSuccessMsg: true,
  //   allowAllMsg: false,
  //   withoutAdminPreUrl: false
  // }): Observable<Res> {
  //   const preUrl = config?.withoutAdminPreUrl ? this.mainUrl : this.baseUrl;
  //   return this.http?.[config.method || 'delete'](preUrl + route, options).pipe(
  //     catchError((err:Res|any) => {
  //       if (config?.allowErrMsg || config?.allowAllMsg) {
  //         this._messageService.add({
  //           severity: 'error',
  //           summary: err?.error?.title || 'Error',
  //           detail: err?.error?.message || config?.errMsg || err?.message || 'Error',
  //         });
  //       }
  //       return throwError(() => err);
  //     }), tap((res: any|Res) => {
  //       if (!res) {
  //         throw new Error('Response is empty');
  //       }
  //       if (!res?.status && !(res as any)?.id && !(res as any)?.data) {
  //         if (config?.allowErrMsg || config?.allowAllMsg) {
  //           this._messageService.add({
  //             severity: 'error',
  //             summary: res?.title || 'Error',
  //             detail: res?.message || config?.errMsg || 'Error',
  //           });
  //         }
  //         throw new Error(res?.message || 'Error');
  //       }

  //       if ((config?.allowSuccessMsg || config?.allowAllMsg)) {
  //         this._messageService.add({ severity: 'success', summary: res?.title || config?.successMsg || 'success', detail: res?.message || 'success' });
  //       }
  //     })
  //   ) as Observable<Res>;
  // }
}

export interface NewApiMethodsCallConfig {
  withoutAdminPreUrl?: boolean;
  withNewApi?: boolean;
  allowMsg?: boolean;
  errMsg?: string;
  allowErrMsg?: boolean;
  successMsg?: string;
  allowSuccessMsg?: boolean;
}

export interface NewGetApiMethodsCallOptions extends NewApiMethodsCallConfig {
  msg?: string;
  allowMsg?: boolean;
  mapFunction?: Function
  insidePropName?: string;
}
export interface NewPostApiMethodsCallOptions extends NewApiMethodsCallConfig{
  method?: 'put' | 'post';
}

export interface NewDeleteApiMethodsCallOptions extends NewApiMethodsCallConfig{
  method?:'delete';
}

export const defaultNewDeleteOptions: NewDeleteApiMethodsCallOptions = {
  allowErrMsg: true,
  allowSuccessMsg: true,
  allowMsg: true,
  withoutAdminPreUrl: false,
  method: 'delete',
  withNewApi: true,
}

export const defaultNewPostOptions: NewPostApiMethodsCallOptions = {
  allowErrMsg: true,
  allowSuccessMsg: true,
  allowMsg: true,
  withoutAdminPreUrl: false,
  method: 'post',
  withNewApi: true,
}

export const defaultNewGetOptions: NewGetApiMethodsCallOptions = {
  allowErrMsg: true,
  allowSuccessMsg: false,
  allowMsg: false,
  withoutAdminPreUrl: false,
  withNewApi: true,
  errMsg: 'Failed to get!',

}

type RequiredPutArrguments = [
  string,
 any | null,
];

type RequiredPostArrguments = [...RequiredPutArrguments, HttpOptions];

type HttpOptions = {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType: 'arraybuffer';
    withCredentials?: boolean;
    transferCache?: {
        includeHeaders?: string[];
    } | boolean;
}

export interface CustomHttpErrorResposne<Error = FailedApiHttpErrorResponse2|any|string> extends HttpErrorResponse {
  error: Error
}

type PostApiResOperatorFunction<T = any> = OperatorFunction<PostApiResponse2<T>, PostApiResponse2<T>>;