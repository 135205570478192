import { CommonModule } from '@angular/common';
import { Component, Input, input, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-custom-search-input',
  templateUrl: './custom-search-input.component.html',
  styleUrls: ['./custom-search-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    FormsModule
  ]
})
export class CustomSearchInputComponent implements OnInit {
  @Input('searchInputValue') set searchInputValue (value: string){
    this.inputValue.set(value);
    this.handleSearchEvent(value, 'set');
  }
  inputValue = signal<string>('')
  clearBtnVisible = input<boolean>(true);

  searchInputPlaceHolder = input<string>('Search ...');
  // local search at the passed table component or parent component search function
  tableSearch = input<boolean>(true);
  // data table
  dt = input<Table>();

  // search type event: 'enter' or 'input'
  searchTypeEvent = input<'enter'|'input'>('enter');

  searchInputValueChange = output<string>()
  constructor() { }

  ngOnInit() {
  }

  onInputEvent(target: EventTarget, eventType: 'enter' | 'input'){
    const value = (target as HTMLInputElement).value
    this.handleSearchEvent(value, eventType);
  }


  // this function to handle the search search input events
  handleSearchEvent(searchValue: string, eventType: 'enter' | 'input' | 'set' | 'clear'): void {
    const isLocalSearch = this.dt() && this.tableSearch();
    const table = this.dt();
    const isMatchedEvent = this.searchTypeEvent() === eventType;

    // Handle clear event
    if (eventType === 'clear') {
      table?.clear();
      if (!isLocalSearch) {
        this.searchInputValueChange.emit(searchValue);
      }
      return;
    }

    // Handle local table search
    if (isLocalSearch) {
      const isProccessedEvent = isMatchedEvent || eventType === 'set';
      if (searchValue && (isProccessedEvent)) {
        table?.filterGlobal(searchValue, 'contains');
        return;
      } 
      if (isProccessedEvent) {
        table?.clear();
        return;
      }
    }

    // Handle external search based on event type match
    if (isMatchedEvent) {
      this.searchInputValueChange.emit(searchValue);
    }
  }

  // this function to clear the search input
  clearSearchInputValue(){
    this.inputValue.set('')
    this.handleSearchEvent('', "clear");
  }
}
